var render = function render() {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    class: _vm.device + "-index",
    attrs: {
      id: "content"
    }
  }, [_c("div", {
    staticClass: "bannerTop"
  }, [_c("el-carousel", {
    ref: "slideCarousel",
    attrs: {
      height: _vm.bannerHeight,
      interval: 6000
    }
  }, _vm._l(_vm.img_list, function (item, index) {
    return _c("el-carousel-item", {
      key: index
    }, [_c("a", {
      attrs: {
        href: item.picLink
      }
    }, [_c("img", {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.device == "pc",
        expression: "device=='pc'"
      }],
      staticClass: "banner-pc",
      staticStyle: {
        width: "100%"
      },
      attrs: {
        src: _vm.title + item.picUrl
      }
    }), _c("img", {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.device == "mobile",
        expression: "device=='mobile'"
      }],
      staticStyle: {
        width: "100%"
      },
      attrs: {
        src: _vm.title + item.mobilePicUrl
      }
    })]), _c("div", {
      staticClass: "textBox"
    }, [_c("p", [_vm._v(_vm._s(item.title1))]), _c("p", [_vm._v(_vm._s(item.title2))])])]);
  }), 1)], 1), _c("div", {
    ref: "index_product",
    staticClass: "indexBlock indexBlock-product"
  }, [_c("div", {
    ref: "index_product_title",
    staticClass: "content-header animate__animated animate__slow animate__fadeInUp"
  }, [_c("div", {
    staticClass: "title animate__animated animate__slow"
  }, [_c("span", [_vm._v(_vm._s(_vm.mapText.product_title[parseInt(_vm.language) - 1]))])]), _c("div", {
    staticClass: "divide-line"
  })]), _c("ul", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.device == "mobile",
      expression: "device=='mobile'"
    }],
    staticClass: "mobile-index-product",
    staticStyle: {
      padding: "0 10px"
    }
  }, [_c("div", [_c("a", {
    attrs: {
      href: "http://www.newhorizonbio.com/#/product_detail?productName=常卫清®"
    }
  }, [_vm.language == 1 ? _c("img", {
    staticStyle: {
      width: "80%",
      "margin-bottom": "10px"
    },
    attrs: {
      src: require("../assets/index/mobile/cwq.png"),
      alt: ""
    }
  }) : _vm._e(), _vm.language == 2 ? _c("img", {
    staticStyle: {
      width: "80%",
      "margin-bottom": "10px"
    },
    attrs: {
      src: require("../assets/index/mobile/cwq_en.png"),
      alt: ""
    }
  }) : _vm._e(), _vm.language == 3 ? _c("img", {
    staticStyle: {
      width: "80%",
      "margin-bottom": "10px"
    },
    attrs: {
      src: require("../assets/index/mobile/cwq_tr.png"),
      alt: ""
    }
  }) : _vm._e()])]), _c("div", [_c("a", {
    attrs: {
      href: "http://www.newhorizonbio.com/#/product_detail?productName=幽幽管®"
    }
  }, [_vm.language == 1 ? _c("img", {
    staticStyle: {
      width: "80%",
      "margin-bottom": "10px"
    },
    attrs: {
      src: require("../assets/index/mobile/yyg.png"),
      alt: ""
    }
  }) : _vm._e(), _vm.language == 2 ? _c("img", {
    staticStyle: {
      width: "80%",
      "margin-bottom": "10px"
    },
    attrs: {
      src: require("../assets/index/mobile/yyg_en.png"),
      alt: ""
    }
  }) : _vm._e(), _vm.language == 3 ? _c("img", {
    staticStyle: {
      width: "80%",
      "margin-bottom": "10px"
    },
    attrs: {
      src: require("../assets/index/mobile/yyg_tr.png"),
      alt: ""
    }
  }) : _vm._e()])]), _c("div", [_c("a", {
    attrs: {
      href: "http://www.newhorizonbio.com/#/product_detail?productName=噗噗管®"
    }
  }, [_vm.language == 1 ? _c("img", {
    staticStyle: {
      width: "80%",
      "margin-bottom": "10px"
    },
    attrs: {
      src: require("../assets/index/mobile/ppg.png"),
      alt: ""
    }
  }) : _vm._e(), _vm.language == 2 ? _c("img", {
    staticStyle: {
      width: "80%",
      "margin-bottom": "10px"
    },
    attrs: {
      src: require("../assets/index/mobile/ppg_en.png"),
      alt: ""
    }
  }) : _vm._e(), _vm.language == 3 ? _c("img", {
    staticStyle: {
      width: "80%",
      "margin-bottom": "10px"
    },
    attrs: {
      src: require("../assets/index/mobile/ppg_tr.png"),
      alt: ""
    }
  }) : _vm._e()])]), _c("div", [_c("a", {
    attrs: {
      href: "http://www.newhorizonbio.com/#/product_detail?productName=宫证清™"
    }
  }, [_vm.language == 1 ? _c("img", {
    staticStyle: {
      width: "80%",
      "margin-bottom": "10px"
    },
    attrs: {
      src: require("../assets/index/mobile/GOGNZHENGQING.jpg"),
      alt: ""
    }
  }) : _vm._e(), _vm.language == 2 ? _c("img", {
    staticStyle: {
      width: "80%",
      "margin-bottom": "10px"
    },
    attrs: {
      src: require("../assets/index/mobile/gongzhengqing_en.png"),
      alt: ""
    }
  }) : _vm._e(), _vm.language == 3 ? _c("img", {
    staticStyle: {
      width: "80%",
      "margin-bottom": "10px"
    },
    attrs: {
      src: require("../assets/index/mobile/gzq_tr.png"),
      alt: ""
    }
  }) : _vm._e()])]), _c("div", [_c("a", {
    attrs: {
      href: "http://www.newhorizonbio.com/#/articleDetail?id=231"
    }
  }, [_vm.language == 1 ? _c("img", {
    staticStyle: {
      width: "80%",
      "margin-bottom": "10px"
    },
    attrs: {
      src: require("../assets/index/mobile/fanaizhong.png"),
      alt: ""
    }
  }) : _vm._e(), _vm.language == 2 ? _c("img", {
    staticStyle: {
      width: "80%",
      "margin-bottom": "10px"
    },
    attrs: {
      src: require("../assets/index/mobile/fanaizhong_en.png"),
      alt: ""
    }
  }) : _vm._e(), _vm.language == 3 ? _c("img", {
    staticStyle: {
      width: "80%",
      "margin-bottom": "10px"
    },
    attrs: {
      src: require("../assets/index/mobile/fanaizhong_tr.png"),
      alt: ""
    }
  }) : _vm._e()])])]), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.device == "pc" && _vm.language == 1,
      expression: "device=='pc' && language == 1"
    }],
    ref: "index_product_items_zh",
    staticClass: "animate__animated animate__slow display-none"
  }, [_c("el-row", {
    staticClass: "margin-bottom-20",
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    staticClass: "cursor-pointer",
    attrs: {
      span: 8
    }
  }, [_c("a", {
    attrs: {
      href: "http://www.newhorizonbio.com/#/product_detail?productName=常卫清®"
    }
  }, [_c("img", {
    attrs: {
      src: require("../assets/index/cwq.png"),
      alt: ""
    }
  })])]), _c("el-col", {
    staticClass: "cursor-pointer",
    attrs: {
      span: 8
    }
  }, [_c("a", {
    attrs: {
      href: "http://www.newhorizonbio.com/#/product_detail?productName=幽幽管®"
    }
  }, [_c("img", {
    attrs: {
      src: require("../assets/index/yyg.png"),
      alt: ""
    }
  })])]), _c("el-col", {
    staticClass: "cursor-pointer",
    attrs: {
      span: 8
    }
  }, [_c("a", {
    attrs: {
      href: "http://www.newhorizonbio.com/#/product_detail?productName=噗噗管®"
    }
  }, [_c("img", {
    attrs: {
      src: require("../assets/index/ppg.png"),
      alt: ""
    }
  })])])], 1), _c("el-row", {
    attrs: {
      gutter: 30
    }
  }, [_c("el-col", {
    staticClass: "cursor-pointer",
    attrs: {
      span: 8,
      offset: 4
    }
  }, [_c("a", {
    attrs: {
      href: "http://www.newhorizonbio.com/#/product_detail?productName=宫证清™"
    }
  }, [_c("img", {
    attrs: {
      src: require("../assets/index/GOGNZHENGQING.jpg"),
      alt: ""
    }
  })])]), _c("el-col", {
    staticClass: "cursor-pointer",
    attrs: {
      span: 8
    }
  }, [_c("a", {
    attrs: {
      href: "http://www.newhorizonbio.com/#/articleDetail?id=231"
    }
  }, [_c("img", {
    attrs: {
      src: require("../assets/index/fanaizhong.png"),
      alt: ""
    }
  })])])], 1)], 1), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.device == "pc" && _vm.language == 2,
      expression: "device=='pc' && language == 2"
    }],
    ref: "index_product_items_en",
    staticClass: "animate__animated animate__slow display-none"
  }, [_c("el-row", {
    staticClass: "margin-bottom-20",
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    staticClass: "cursor-pointer",
    attrs: {
      span: 8
    }
  }, [_c("a", {
    attrs: {
      href: "http://www.newhorizonbio.com/#/product_detail?productName=常卫清®"
    }
  }, [_c("img", {
    attrs: {
      src: require("../assets/index/cwq_en.png"),
      alt: ""
    }
  })])]), _c("el-col", {
    staticClass: "cursor-pointer",
    attrs: {
      span: 8
    }
  }, [_c("a", {
    attrs: {
      href: "http://www.newhorizonbio.com/#/product_detail?productName=幽幽管®"
    }
  }, [_c("img", {
    attrs: {
      src: require("../assets/index/yyg_en.png"),
      alt: ""
    }
  })])]), _c("el-col", {
    staticClass: "cursor-pointer",
    attrs: {
      span: 8
    }
  }, [_c("a", {
    attrs: {
      href: "http://www.newhorizonbio.com/#/product_detail?productName=噗噗管®"
    }
  }, [_c("img", {
    attrs: {
      src: require("../assets/index/ppg_en.png"),
      alt: ""
    }
  })])])], 1), _c("el-row", {
    attrs: {
      gutter: 30
    }
  }, [_c("el-col", {
    staticClass: "cursor-pointer",
    attrs: {
      span: 8,
      offset: 4
    }
  }, [_c("a", {
    attrs: {
      href: "http://www.newhorizonbio.com/#/product_detail?productName=宫证清™"
    }
  }, [_c("img", {
    attrs: {
      src: require("../assets/index/gongzhengqing_en.png"),
      alt: ""
    }
  })])]), _c("el-col", {
    staticClass: "cursor-pointer",
    attrs: {
      span: 8
    }
  }, [_c("a", {
    attrs: {
      href: "http://www.newhorizonbio.com/#/articleDetail?id=231"
    }
  }, [_c("img", {
    attrs: {
      src: require("../assets/index/panda.png"),
      alt: ""
    }
  })])])], 1)], 1), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.device == "pc" && _vm.language == 3,
      expression: "device=='pc' && language == 3"
    }],
    ref: "index_product_items_tr",
    staticClass: "animate__animated animate__slow display-none"
  }, [_c("el-row", {
    staticClass: "margin-bottom-20",
    attrs: {
      gutter: 20
    }
  }, [_c("el-col", {
    staticClass: "cursor-pointer",
    attrs: {
      span: 8
    }
  }, [_c("a", {
    attrs: {
      href: "http://www.newhorizonbio.com/#/product_detail?productName=常卫清®"
    }
  }, [_c("img", {
    attrs: {
      src: require("../assets/index/cwq_tr.png"),
      alt: ""
    }
  })])]), _c("el-col", {
    staticClass: "cursor-pointer",
    attrs: {
      span: 8
    }
  }, [_c("a", {
    attrs: {
      href: "http://www.newhorizonbio.com/#/product_detail?productName=幽幽管®"
    }
  }, [_c("img", {
    attrs: {
      src: require("../assets/index/yyg_tr.png"),
      alt: ""
    }
  })])]), _c("el-col", {
    staticClass: "cursor-pointer",
    attrs: {
      span: 8
    }
  }, [_c("a", {
    attrs: {
      href: "http://www.newhorizonbio.com/#/product_detail?productName=噗噗管®"
    }
  }, [_c("img", {
    attrs: {
      src: require("../assets/index/ppg_tr.png"),
      alt: ""
    }
  })])])], 1), _c("el-row", {
    attrs: {
      gutter: 30
    }
  }, [_c("el-col", {
    staticClass: "cursor-pointer",
    attrs: {
      span: 8,
      offset: 4
    }
  }, [_c("a", {
    attrs: {
      href: "http://www.newhorizonbio.com/#/product_detail?productName=宫证清™"
    }
  }, [_c("img", {
    attrs: {
      src: require("../assets/index/gzq_tr.png"),
      alt: ""
    }
  })])]), _c("el-col", {
    staticClass: "cursor-pointer",
    attrs: {
      span: 8
    }
  }, [_c("a", {
    attrs: {
      href: "http://www.newhorizonbio.com/#/articleDetail?id=231"
    }
  }, [_c("img", {
    attrs: {
      src: require("../assets/index/fanaizhong_tr.png"),
      alt: ""
    }
  })])])], 1)], 1)]), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.device == "pc",
      expression: "device=='pc'"
    }]
  }, [_vm.language == 2 ? _c("div", {
    staticClass: "content-header"
  }, [_vm._m(0), _c("div", {
    staticClass: "divide-line"
  })]) : _vm._e(), _c("a", {
    attrs: {
      target: "_blank",
      href: "https://nuohui-public.oss-cn-hangzhou.aliyuncs.com/static/nh-official-website/robot.mp4"
    }
  }, [_vm.language == 1 ? _c("img", {
    attrs: {
      src: require("../assets/index/index_pic.png"),
      alt: ""
    }
  }) : _vm._e(), _vm.language == 2 ? _c("img", {
    attrs: {
      src: require("../assets/index/index_pic_en.png"),
      alt: ""
    }
  }) : _vm._e(), _vm.language == 3 ? _c("img", {
    attrs: {
      src: require("../assets/index/index_pic_tr.png"),
      alt: ""
    }
  }) : _vm._e()])]), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.device == "mobile",
      expression: "device=='mobile'"
    }]
  }, [_vm.language == 2 ? _c("div", {
    staticClass: "content-header"
  }, [_vm._m(1), _c("div", {
    staticClass: "divide-line"
  })]) : _vm._e(), _vm.language == 1 ? _c("a", {
    attrs: {
      target: "_blank",
      href: "https://nuohui-public.oss-cn-hangzhou.aliyuncs.com/static/nh-official-website/robot.mp4"
    }
  }, [_c("img", {
    attrs: {
      src: require("../assets/index/mobile/shouchuang.png"),
      alt: ""
    }
  })]) : _vm._e(), _vm.language == 2 ? _c("a", {
    attrs: {
      target: "_blank",
      href: "https://nuohui-public.oss-cn-hangzhou.aliyuncs.com/static/nh-official-website/robot.mp4"
    }
  }, [_c("img", {
    attrs: {
      src: require("../assets/index/mobile/shouchuang_en.png"),
      alt: ""
    }
  })]) : _vm._e(), _vm.language == 3 ? _c("a", {
    attrs: {
      target: "_blank",
      href: "https://nuohui-public.oss-cn-hangzhou.aliyuncs.com/static/nh-official-website/robot.mp4"
    }
  }, [_c("img", {
    attrs: {
      src: require("../assets/index/mobile/shouchuang_tr.png"),
      alt: ""
    }
  })]) : _vm._e()]), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.device == "pc",
      expression: "device=='pc'"
    }],
    staticClass: "indexBlock indexBlock-hkModule",
    staticStyle: {
      "padding-bottom": "40px"
    }
  }, [_c("div", {
    staticClass: "content-header animate__animated animate__slow animate__fadeInUp"
  }, [_c("div", {
    staticClass: "title animate__animated animate__slow"
  }, [_c("span", [_vm._v(_vm._s(_vm.mapText.international_title[parseInt(_vm.language) - 1]))])]), _c("div", {
    staticClass: "divide-line"
  })]), _vm._m(2)]), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.device == "mobile",
      expression: "device=='mobile'"
    }],
    staticClass: "indexBlock indexBlock-product"
  }, [_c("div", {
    ref: "index_hk_title",
    staticClass: "content-header animate__animated animate__slow animate__fadeInUp"
  }, [_c("div", {
    staticClass: "title animate__animated animate__slow"
  }, [_c("span", [_vm._v(_vm._s(_vm.mapText.international_title[parseInt(_vm.language) - 1]))])]), _c("div", {
    staticClass: "divide-line"
  })]), _c("img", {
    attrs: {
      src: require("../assets/index/mobile/xianggang.png"),
      alt: ""
    }
  })]), _c("div", {
    staticClass: "indexBlock indexBlock-hkModule nh-story",
    class: {
      "nh-story-mobile": _vm.device == "mobile"
    }
  }, [_c("div", {
    staticClass: "content-header animate__animated animate__slow animate__fadeInUp"
  }, [_c("div", {
    staticClass: "title animate__animated animate__slow"
  }, [_c("span", [_vm._v(_vm._s(_vm.mapText.nh_story[parseInt(_vm.language) - 1]))])]), _c("div", {
    staticClass: "divide-line"
  }), _vm.language == 1 || _vm.language == 3 ? _c("div", {
    staticClass: "cursor-pointer",
    class: {
      "nh-story-subTitle": _vm.device == "mobile"
    },
    on: {
      click: function ($event) {
        return _vm.changeRouter("/trustNh");
      }
    }
  }, [_vm.device == "pc" ? _c("span", {
    staticClass: "font-size-05em"
  }, [_vm._v(_vm._s(_vm.mapText.nh_story_subTitle[parseInt(_vm.language) - 1]))]) : _vm._e(), _vm.device == "mobile" ? _c("span", {
    staticStyle: {
      "font-size": "14px",
      color: "#222222"
    }
  }, [_vm._v(_vm._s(_vm.mapText.nh_story_subTitle[parseInt(_vm.language) - 1]))]) : _vm._e()]) : _vm._e()])]), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.device == "pc",
      expression: "device=='pc'"
    }],
    staticClass: "index-nhStory"
  }, [_vm._m(3), _c("img", {
    attrs: {
      src: require("../assets/index/index_story.png"),
      alt: ""
    }
  })]), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.device == "mobile",
      expression: "device=='mobile'"
    }],
    staticClass: "index-nhStory-mobile"
  }, [_vm._m(4), _c("img", {
    attrs: {
      src: require("../assets/index/mobile/index_story.png"),
      alt: ""
    }
  })]), _c("div", {
    staticClass: "indexBlock indexBlock-hkModule bg-white index-changeLife padding-bottom-0",
    class: {
      "index-changeLife-mobile": _vm.device == "mobile"
    }
  }, [_c("div", {
    staticClass: "content-header animate__animated animate__slow animate__fadeInUp padding-bottom-0"
  }, [_c("div", {
    staticClass: "title animate__animated animate__slow"
  }, [_c("span", [_vm._v(_vm._s(_vm.mapText.changeLife[parseInt(_vm.language) - 1]))])]), _c("div", {
    staticClass: "divide-line"
  }), _vm.device == "pc" ? _c("div", [_c("a", {
    attrs: {
      href: _vm.lifeHref
    }
  }, [_vm.language == 1 ? _c("img", {
    staticClass: "margin-top-20",
    attrs: {
      src: require("../assets/index/changeLife.png"),
      alt: ""
    }
  }) : _vm._e(), _vm.language == 2 ? _c("img", {
    staticClass: "margin-top-20",
    attrs: {
      src: require("../assets/index/changeLife_en.png"),
      alt: ""
    }
  }) : _vm._e(), _vm.language == 3 ? _c("img", {
    staticClass: "margin-top-20",
    attrs: {
      src: require("../assets/index/changeLife_tr.png"),
      alt: ""
    }
  }) : _vm._e()])]) : _vm._e(), _vm.device == "mobile" ? _c("div", [_c("a", {
    attrs: {
      href: _vm.lifeHref
    }
  }, [_vm.language == 1 ? _c("img", {
    staticClass: "margin-top-20",
    attrs: {
      src: require("../assets/index/mobile/changeLife.png"),
      alt: ""
    }
  }) : _vm._e(), _vm.language == 2 ? _c("img", {
    staticClass: "margin-top-20",
    attrs: {
      src: require("../assets/index/mobile/changeLife_en.png"),
      alt: ""
    }
  }) : _vm._e(), _vm.language == 3 ? _c("img", {
    staticClass: "margin-top-20",
    attrs: {
      src: require("../assets/index/mobile/changeLife_tr.png"),
      alt: ""
    }
  }) : _vm._e()])]) : _vm._e()])]), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showLpayer,
      expression: "showLpayer"
    }],
    staticClass: "videomodel"
  }, [_c("div", {
    staticClass: "videomodel-wrapper"
  }, [_c("a", {
    staticClass: "videomodel-close",
    attrs: {
      href: "javascript:;"
    },
    on: {
      click: _vm.closePlayer
    }
  }, [_c("i", {
    staticClass: "el-icon-close"
  })]), _c("video", {
    staticClass: "videomodel-video",
    attrs: {
      src: "https://nuohui-public.oss-cn-hangzhou.aliyuncs.com/static/nh-official-website/juejiang.mp4",
      type: "video/mp4",
      controls: "controls",
      poster: ""
    }
  })])])]);
};

var staticRenderFns = [function () {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticClass: "title animate__animated animate__slow"
  }, [_c("span", [_vm._v("Our automated production line")])]);
}, function () {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    staticClass: "title animate__animated animate__slow"
  }, [_c("span", [_vm._v("Our automated production line")])]);
}, function () {
  var _vm = this,
      _c = _vm._self._c;

  return _c("div", [_c("div", {
    staticClass: "margin-bottom-20"
  }, [_c("img", {
    attrs: {
      src: require("../assets/index/index_pic_hk.png"),
      alt: ""
    }
  })])]);
}, function () {
  var _vm = this,
      _c = _vm._self._c;

  return _c("a", {
    attrs: {
      target: "_blank",
      href: "https://nuohui-public.oss-cn-hangzhou.aliyuncs.com/static/nh-official-website/juejiang.mp4"
    }
  }, [_c("i", {
    staticClass: "el-icon-video-play nh-story-iconPlay cursor-pointer"
  })]);
}, function () {
  var _vm = this,
      _c = _vm._self._c;

  return _c("a", {
    attrs: {
      target: "_blank",
      href: "https://nuohui-public.oss-cn-hangzhou.aliyuncs.com/static/nh-official-website/juejiang.mp4"
    }
  }, [_c("i", {
    staticClass: "el-icon-video-play nh-story-iconPlay cursor-pointer"
  })]);
}];
render._withStripped = true;
export { render, staticRenderFns };