import { mapState } from 'vuex';
const mapText = {
  clearC_text_h1: ['Clear-C', 'Clear-C', 'Clear-C'],
  clearC_text_h3: ['临床试验', 'Clinical trials', '臨床試驗'],
  clearC_text_p: ['是检验医学产品的标准', 'are the standard for all products', '是檢驗醫學產品的標準'],
  product_title: ['高发癌症 居家早筛', 'Our tests', '高發癌症 居家早篩'],
  international_title: ['扎根香港 进军国际化', 'International R&D center in HongKong Science Park', '紮根香港 進軍國際化'],
  nh_story: ['诺辉的故事', 'Our story', '諾輝的故事'],
  nh_story_subTitle: ['从中国首个癌症早筛前瞻性大规模多中心注册临床试验开始', '', '從中國首個癌症早篩前瞻性大規模多中心注册臨床試驗開始'],
  changeLife: ['改变生命的轨迹', 'Changing the trajectory of life', '改變生命的軌跡'],
  product_slogan: ['查一次，更放心', 'check it,and rest assured', '查一次，更放心'],
  product_detail_button: ['产品详情', 'Learn More', '產品詳情'],
  vision_learnMore: ['了解更多', 'Learn More', '瞭解更多'],
  clearC_textDetail_p1: ['中国首个癌症早筛前瞻性大规模多中心注册临床试验，', 'China’s first prospective, large-scale and multi-center registered clinical trial for early cancer screening ', '中國首個癌症早篩前瞻性大規模多中心注册臨床試驗，'],
  clearC_textDetail_p2: ['由全国8家大型三甲医院联合展开，', 'It is jointly carried out by 8 large first-class hospitals across the country', '由全國8家大型三甲醫院聯合展開，'],
  clearC_textDetail_p3: ['为诺辉健康早筛产品的性能验证，提供了最高科学等级的科学依据。', 'It provides the highest scientific basis for the validation of clinical performance of New Horizon Health’s earlier detection products', '為諾輝健康早篩產品的效能驗證，提供了最高科學等級的科學依據。'],
  viewMore: ['了解更多', 'Learn more', '瞭解更多'],
  clearC_title: ['诺辉健康完成了中国首个癌症早筛', "Nuohui Health completed China's first cancer early screening", '諾輝健康完成了中國首個癌症早篩'],
  clearC_title_2: ['前瞻性大规模多中心的注册临床试验', 'Prospective large-scale multi-center registered clinical trial', '前瞻性大規模多中心的注册臨床試驗'],
  clearC_slogan: ['检验医学产品的“硬核”指标', 'The core indicators of laboratory medicine products', '檢驗醫學産品的“硬核”指標'],
  clear_title_item_01: ['常卫清®', 'ColoClear', '常衛清®'],
  clear_title_item_02: ['宫证清™', 'CerviClear', '宮證清™'],
  clear_title_item_03: ['苷证清™', 'LiverClear', '苷證清™'],
  clear_title_item_04: ['易必清™', 'NPClear', '易必清™'],
  clear_title_item_05: ['PANDA', 'PANDA', 'PANDA'],
  clear_subTitle_item_01: ['ColoClear', 'ColoClear', 'ColoClear'],
  clear_subTitle_item_02: ['CerviClear', 'CerviClear', 'CerviClear'],
  clear_subTitle_item_03: ['LiverClear', 'LiverClear', 'LiverClear'],
  clear_subTitle_item_04: ['NPClear', 'NPClear', 'NPClear'],
  clear_subTitle_item_05: ['Pan-Cancer', 'Pan-Cancer', 'Pan-Cancer'],
  clear_content_item_01: ['完成中国首个癌症早筛大规模前瞻性多中心注册临床试验Clear-C', 'The first large-scale, prospective, multi-center, clinical trial for cancer screening in China(Clear-C)', '完成中國首個癌症早篩大規模前瞻性多中心註冊臨床試驗Clear-C'],
  clear_content_item_02: ['2022年6月启动前瞻性大规模多中心注册临床试验\n2022年11月发布预临床数据', 'The fourth quarter of 2022 or Start in the first quarter of 2023 start clinical trial\nPre-clinical data released in November 2022', '2022年6月啟動前瞻性大規模多中心註冊臨床試驗\n2022年11月發布預臨床數據'],
  clear_content_item_03: ['预计2024年启动注册临床试验', 'Launch of registration clinical trials in 2024', '預計2024年啓動注冊臨床試驗'],
  clear_content_item_04: ['2022年1月与中山大学、中山大学附属肿瘤医院举行鼻咽癌筛查专利转让暨合作签约仪式\n2022年预临床研究如期推进', 'In January 2022, the patent transfer ceremony for nasopharyngeal cancer screening was held with Sun Yat-sen University and the Affiliated Cancer Hospital of Sun Yat-sen University\nThe pre-clinical study in 2022 is progressing as scheduled', '2022年1月與中山大學、中山大學附屬腫瘤醫院舉行鼻咽癌篩查專利轉讓暨合作簽約儀式\n2022年預臨床研究如期推進'],
  clear_content_item_05: ['北大医学-诺辉健康泛癌种早筛早诊PANDA队列研究项目', 'Peking University Health Science Center - New Horizon Health Pan-cancer Early Detection in China', '北大醫學-諾輝健康泛癌種早篩早診PANDA隊列研究專案']
};
const clearc_items = [{
  title: mapText.clear_title_item_01,
  subTitle: mapText.clear_subTitle_item_01,
  content: mapText.clear_content_item_01
}, {
  title: mapText.clear_title_item_02,
  subTitle: mapText.clear_subTitle_item_02,
  content: mapText.clear_content_item_02
}, {
  title: mapText.clear_title_item_03,
  subTitle: mapText.clear_subTitle_item_03,
  content: mapText.clear_content_item_03
}, {
  title: mapText.clear_title_item_04,
  subTitle: mapText.clear_subTitle_item_04,
  content: mapText.clear_content_item_04
}, {
  title: mapText.clear_title_item_05,
  subTitle: mapText.clear_subTitle_item_05,
  content: mapText.clear_content_item_05
}];
export default {
  name: "index",

  data() {
    return {
      title: this.$api.api_title,
      img_list: [],
      card_list: {},
      productList: [],
      news_list: {},
      showLpayer: false,
      playLink: '',
      mapText,
      user_title: '',
      user_content: '',
      user_homeImage: '',
      vision_title: '',
      vision_content: '',
      vision_link: '',
      clearC_imgList: [require('../assets/index/clearC.jpg')],
      clearc_items,
      lifeHref: ''
    };
  },

  mounted() {
    this.lifeHref = window.location.origin + '/#/user_story';
    window.addEventListener('scroll', this.handleScroll, true);
    this.slideBanner();
    setTimeout(() => {
      this.$refs.index_product_items_zh.classList.add('animate__zoomIn');
      this.$refs.index_product_items_zh.classList.remove('display-none');
      this.$refs.index_product_items_en.classList.add('animate__zoomIn');
      this.$refs.index_product_items_en.classList.remove('display-none');
      this.$refs.index_product_items_tr.classList.add('animate__zoomIn');
      this.$refs.index_product_items_tr.classList.remove('display-none');
    }, 500);
  },

  computed: { ...mapState({
      device: state => state.device,
      language: state => state.language
    }),

    bannerHeight() {
      return this.device == 'pc' ? '10.825rem' : '100vh';
    }

  },
  watch: {},

  created() {
    this.getData(); // this.title = this.$api.api_title;
  },

  destroyed() {
    window.removeEventListener('scroll', this.handleScroll, true);
  },

  methods: {
    getData() {
      //顶部大图
      this.$http.get(this.$api.cmsHome_bannerList, {
        params: {
          nhLanguage: this.$store.state.language
        }
      }).then(res => {
        if (res.code == 0) {
          if (res.data) {
            this.img_list = res.data;
          }
        }
      }); //愿景

      this.$http.get(this.$api.hometitleList).then(res => {
        if (res.code == 0) {
          if (res.data) {
            this.card_list = res.data[0];

            if (this.language == 1) {
              this.vision_title = res.data[0].cnTitle;
              this.vision_content = res.data[0].cnTitleDetail;
            }

            if (this.language == 2) {
              this.vision_title = res.data[0].enTitle;
              this.vision_content = res.data[0].enTitleDetail;
            }

            if (this.language == 3) {
              this.vision_title = res.data[0].trTitle;
              this.vision_content = res.data[0].trTitleDetail;
            }

            this.vision_link = res.data[0].trHomeImage;
          }
        }
      }); //产品体系

      this.$http.get(this.$api.cmsHome_productList, {
        params: {
          nhLanguage: this.$store.state.language
        }
      }).then(res => {
        if (res.code == 0) {
          if (res.data) {
            this.productList = res.data;
          }
        }
      }); //用户故事

      this.$http.get(this.$api.cmsHomeNewsList).then(res => {
        if (res.code == 0) {
          if (res.data) {
            this.news_list = res.data[0];
            console.log('🚀 ~ this.news_list', this.news_list);

            if (this.language == 1) {
              this.user_title = res.data[0].cnTitle;
              this.user_content = res.data[0].cnContext;
              this.user_homeImage = res.data[0].cnHomeImage;
            }

            if (this.language == 2) {
              this.user_title = res.data[0].enTitle;
              this.user_content = res.data[0].enContext;
              this.user_homeImage = res.data[0].enHomeImage;
            }

            if (this.language == 3) {
              this.user_title = res.data[0].trTitle;
              this.user_content = res.data[0].trContext;
              this.user_homeImage = res.data[0].trHomeImage;
            }
          }
        }
      });
    },

    closePlayer() {
      this.showLpayer = false;
      this.playLink = '';
    },

    playVideo(event) {
      this.showLpayer = true;
      var el = event.target;
      this.playLink = el.getAttribute('data-link');
    },

    cardLisk(event) {
      var el = event.target;

      if (el.getAttribute('data-link')) {
        window.location.href = el.getAttribute('data-link');
      }
    },

    handleScroll() {
      // clear-c
      // const clearC_title = this.$refs.clearC_title.getBoundingClientRect().top
      // this.changeClass(clearC_title, this.$refs.clearC_title, 'animate__fadeInUp', 500)
      const clearC_top = this.$refs.clearC.getBoundingClientRect().top; // this.changeClass(clearC_top, this.$refs.clearC_text_h1, 'animate__fadeInUp', 200)

      this.changeClass(clearC_top, this.$refs.clearC_text_h3, 'animate__fadeInUp', 200);
      this.changeClass(clearC_top, this.$refs.clearC_text_h3_2, 'animate__fadeInUp', 200); // this.changeClass(clearC_top, this.$refs.clearC_text_p, 'animate__fadeInUp', 200)

      const clearC_text_Detail = this.$refs.clearC_text_Detail.getBoundingClientRect().top;
      this.changeClass(clearC_text_Detail, this.$refs.clearC_textDetail, 'animate__fadeInUp', 500); // 产品体系

      const index_product = this.$refs.index_product.getBoundingClientRect().top;
      this.changeClass(index_product, this.$refs.index_product_items, 'animate__fadeInUp', 400); // 用户故事

      const index_user = this.$refs.index_user.getBoundingClientRect().top;
      this.changeClass(index_user, this.$refs.index_user_title, 'animate__fadeInUp', 400);
      this.changeClass(index_user, this.$refs.index_user_content, 'animate__fadeInUp', 400);
      this.changeClass(index_user, this.$refs.index_user_img, 'animate__zoomIn', 400);
    },

    changeClass(distance, target, animate, top) {
      if (distance < top) {
        target.classList.add(animate);
        target.classList.remove('display-none');
      }
    },

    // 滑动切换
    slideBanner() {
      //选中的轮播图
      // const box = this.$refs.slideCarousel
      const box = document.querySelector('.el-carousel__container');
      let startPoint = 0;
      let stopPoint = 0; //重置坐标

      var resetPoint = function () {
        startPoint = 0;
        stopPoint = 0;
      }; //手指按下


      box.addEventListener("touchstart", function (e) {
        //手指点击位置的X坐标
        startPoint = e.changedTouches[0].pageX;
      }); //手指滑动

      box.addEventListener("touchmove", function (e) {
        //手指滑动后终点位置X的坐标
        stopPoint = e.changedTouches[0].pageX;
      }); //当手指抬起的时候，判断图片滚动离左右的距离

      let that = this;
      box.addEventListener("touchend", function (e) {
        console.log("1：" + startPoint);
        console.log("2：" + stopPoint);

        if (stopPoint == 0 || startPoint - stopPoint == 0) {
          resetPoint();
          return;
        }

        if (startPoint - stopPoint > 0) {
          resetPoint();
          that.$refs.slideCarousel.next();
          return;
        }

        if (startPoint - stopPoint < 0) {
          resetPoint();
          that.$refs.slideCarousel.prev();
          return;
        }
      });
    },

    changeRouter(path) {
      this.$router.push(path);
    }

  }
};